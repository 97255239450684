<script setup>
import {
  reactive,
  onBeforeUnmount,
  ref,
  getCurrentInstance,
  onBeforeMount,
} from "vue";
import StepifyDistanceFrame from "@/components/distance/StepifyDistanceFrame.vue";
import VideoComponent from "@/components/common/VideoComponent.vue";
import useLoop from "@/utils/loop";
import { getFrameArea } from "@/utils/resolution";
import liveness from "@/plugins/axios/http/liveness";
import { useSessionStore } from "@/plugins/pinia/stores/session";
import { useUserStore } from "@/plugins/pinia/stores/user";
import { abortFlow, nextStep } from "@/utils/flow";
import { createSession } from "@/plugins/axios/http/sessions";

const sessionStore = useSessionStore();
const userStore = useUserStore();
let session;

const instance = ref(null);
const frameAreas = ref(null);

const loop = useLoop({
  interval: 1000,
  delay: 1000,
});

const frame = reactive({
  ref: null,
  async finish(status) {
    if (status === "success") {
      loop.stop();
      const livenessResult = await liveness.getResult(session);

      if (livenessResult?.data?.result) {
        userStore.setLivenessResult({
          status: livenessResult.data.result,
          data: livenessResult.data,
        });
        await nextStep();
      } else {
        await abortFlow();
      }
    } else {
      await abortFlow();
    }
  },
});

const video = reactive({
  ref: null,
  async start() {
    await loop.start(async () => {
      const response = await liveness.getDistance(
        session,
        video.ref.screenshot({
          quality: 0.6,
          type: "file",
        })
      );
      if (!loop.isWorking()) return;
      framing(response);
    });
  },
  stop() {
    abortFlow();
  },
});

const renderFrame = ref(false);

onBeforeMount(async () => {
  instance.value = getCurrentInstance().appContext.app._container;
  session = (await createSession()).data.id;
  sessionStore.session = session;
});

onBeforeUnmount(() => {
  loop.stop();
});

function handleStart() {
  renderFrame.value = true;
  video.start();
}

function framing(response) {
  if (!frameAreas.value)
    frameAreas.value = getFrameArea(instance.value, video.ref.settings());
  if (
    frameAreas.value &&
    Object.prototype.hasOwnProperty.call(response, "data")
  ) {
    const area = Math.round(response.data.area * 100);
    if (frame.ref.step === "further") {
      const furtherAreaRange = frameAreas.value.further;
      if (area > furtherAreaRange.max) frame.ref.current.hint = "too-close";
      else if (area < furtherAreaRange.min) frame.ref.current.hint = "too-far";
      else frame.ref.force();
    } else if (frame.ref.step === "closer") {
      const closerAreaRange = frameAreas.value.closer;
      if (area > closerAreaRange.max) frame.ref.current.hint = "too-close";
      else if (area < closerAreaRange.min) frame.ref.current.hint = "too-far";
      else frame.ref.force();
    }
  }
}
</script>

<template>
  <div class="container">
    <div class="frame">
      <stepify-distance-frame
        v-if="renderFrame"
        :ref="(ref) => (frame.ref = ref)"
        @finish="frame.finish"
      />
    </div>
    <video-component
      :ref="(ref) => (video.ref = ref)"
      @start="handleStart"
      @stop="video.stop"
      @error="video.stop"
    />
  </div>
</template>

<style scoped>
.container {
  position: relative;
  height: 100%;
  width: 100%;
  overflow: hidden;
}

.frame {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}
</style>
